import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.svg";
const Header = () => {
  return (
    <header className="py-3 mb-12 shadow">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <h2 className="font-bold text-3xl">
            Dream<span className="text-violet-700">House</span>
          </h2>
        </Link>
      </div>
    </header>
  );
};

export default Header;
